@if (form) {
  <div class="block">
    <div class="block-grow">
      <div class="heading2">
        Email Delivery Strategy
      </div>

      <div class="body-secondary">
        To switch between different email delivery services, such as SendGrid or Haraka, for sending outbound emails.
      </div>

      <app-toggle-switch
        class='input'
        appearance='sapphire-selector'
        [inputFormControl]='form.controls.outboundMailStrategy'
        trueLabel='SendGrid'
        falseLabel='Haraka'
      />
    </div>
  </div>
}
