// Common
import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RX
import { Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap, take } from 'rxjs/operators';

// Services
import { BackgroundJobsService } from '@modules/common/services/background-jobs.service';
import { BaseStitchChildService } from '@modules/common/services/base-stitch-child.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { PermissionParticipantsService } from './permission-participants.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { Like } from '@modules/common/types/like';
import { Message } from '../types/message';
import { MessagesFilters } from '../types/messages-filters';
import { Participant } from '../types/participant';
import { PermissionParticipant } from '../types/permission-participant';
import { StitchType } from '@modules/common/types/stitch-type';
import { Job } from '@modules/common/types/background-job';
import { Attachment } from '@modules/attachments/types/attachment';
import { MessagesCounts } from '@modules/messages/types/messages-counts';
import { MessagesBunchUpdateOptions } from '@modules/messages/types/messages-bunch-update-options';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable()
export class MessagesService extends BaseStitchChildService<Message, MessagesFilters> implements CalendarEventsService {
  protected url = environment.baseUrl + '/api/mail/messages/';
  protected stitchType = StitchType.message;
  protected filtersConstructor = MessagesFilters;

  constructor (
    protected http: HttpClient,
    @Optional() linkedInfoService: LinkedInfoService,
    protected toasterService: ToasterService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
    private permissionParticipantsService: PermissionParticipantsService,
    private backgroundJobs: BackgroundJobsService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  /**
   * Methods
   */

  public bunchUpdate(
    filters: Partial<MessagesFilters>,
    changes: MessagesBunchUpdateOptions & Partial<Pick<Message, 'folderId'>>,
    config: FeedbackConfig
  ): Observable<void> {
    return super.bunchUpdate(filters, changes, config);
  }

  getItem(id: string): Observable<Message> {
    return super.getItem(id)
      .pipe(
        switchMap((message) => {
          return this.permissionParticipantsService.search({
            values: [].concat(
              message.from.map(({ address }) => address),
              message.to.map(({ address }) => address),
              message.cc.map(({ address }) => address),
              message.bcc.map(({ address }) => address)
            )
          })
            .pipe(
              map(({ items: permissions }) => this.fillMessageVIP(message, permissions))
            );
        }),
        map(message => new Message(message)),
      );
  }

  getMessagesCount(): Observable<MessagesCounts> {
    return this.http.get<MessagesCounts>(environment.baseUrl + '/api/mail/messages/counts')
      .pipe(map((counts) => ({ ...counts, all_messages: counts['inbox'] })));
  }

  create(
    messageInstance: Message,
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<Message> {
    return this.http.post<{ message: Message, success: boolean, job: Job }>(
      `${environment.baseUrl}/api/mail/messages`,
      messageInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ message, success, job }) => {
          if (!success) { return; }

          emit && this.forceRefresh();

          if (job) {
            const actions = [];

            if (job.delay) {
              actions.push({
                text: 'Undo',
                handler: this.backgroundJobs
                  .undo({ type: job.type, id: job.id })
                  .pipe(
                    take(1),
                    tap(() => {
                      this.toasterService.show({ text: 'Message sending undone.' });
                    }),
                    catchError(() => {
                      this.toasterService.show({ text: 'Message sending can\'t be undone.' });
                      return of(null);
                    })
                  )
              });
            }

            this.toasterService.show({
              text: 'Sending...',
              icon: 'send',
              actions,
              duration: job.delay,
              countdown: true,
              afterTimeout: () => {
                this.toasterService.show({ text: 'Message sent.', icon: 'send' });
                this.forceRefresh();
              }
            });
          } else if (messageInstance.scheduled) {
            this.toasterService.show({
              text: 'Message scheduled',
              icon: 'send'
            });
          }
        }),
        map(({ message }) => new Message(message)),
        switchMap(message => this.processKnowledgeItems({
          linkedInfo: messageInstance.linkedInfo,
          knots: messageInstance.knots,
          tags: messageInstance.tags
        }, message)),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  update(
    messageInstance: Message,
    { emit, displayToast, toastMessage }: FeedbackConfig = { emit: true }
  ): Observable<Message> {
    return this.http.put<{ message: Message, success: boolean }>(
      environment.baseUrl + '/api/mail/messages/' + messageInstance.id, messageInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ message, success }) => {
          if (success) {
            emit && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'Message updated' });
          }
        }),
        map(({ message }) => new Message(message)),
        catchError(error => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  sendAll({ emit, toast, message }: FeedbackConfig = { emit: true, toast: true }): Observable<void> {
    return this.http.post<{ success: boolean }>(environment.baseUrl + '/api/mail/messages/send-all', null)
      .pipe(
        tap(() => {
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || 'Messages successfully sent' });
        }),
        map(() => void 0)
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime })
      .pipe(
        map(({ items }) => items.map(message => message.asAngularCalendarEvent()))
      );
  }

  getAutocompleteSuggestions(inputFilters: Like<MessagesFilters> = {}): AutocompleteFactory<Message> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<MessagesFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title?.trim()) {
        filters.query = title;
        filters.esAnalyzer = 'ngrams_2_7';
        filters.esPriority = 'title';
        filters.esMultiMatchType = 'best_fields';
      }

      return this.search(filters)
        .pipe(
          map((response: BaseSearchResponse<Message>) => response.items.map(message => ({
            title: message.subject,
            value: message.id,
            source: message
          }))),
        );
    };
  }

  @warmUpObservable
  read(filters: Partial<MessagesFilters>, read: boolean, config?: FeedbackConfig): Observable<void> {
    const message = `All appropriate messages successfully marked as ${ read ? 'read' : 'unread' }`;

    return this.bunchUpdate(filters, { unread: !read }, { ...config, message: config?.message ?? message });
  }

  @warmUpObservable
  spam(filters: Partial<MessagesFilters>, spam: boolean) {
    const message = `All appropriate messages successfully ${spam ? '' : 'un'}marked as spam`;

    return this.bunchUpdate(filters, { spam }, { message });
  }

  @warmUpObservable
  bulk(filters: Partial<MessagesFilters>, bulk: boolean) {
    const message = `All appropriate messages successfully ${bulk ? '' : 'un'}marked as bulk`;

    return this.bunchUpdate(filters, { bulk }, { message });
  }

  downloadOriginal(message: Message) {
    const attachment = new Attachment({
      attachmentId: 'eml-' + message.id,
      fileName: `${message.emlFileName || message.subject}.eml`,
      fileType: 'message/rfc822',
      stitchItem: message
    });

    attachment.download();
  }

  createInstance(item): Message {
    return new Message(item);
  }

  private fillMessageVIP(message: Message, permissions: PermissionParticipant[]) {
    return {
      ...message,
      from: message.from.map(participant => this.fillParticipantVIP(participant, permissions)),
      to: message.to.map(participant => this.fillParticipantVIP(participant, permissions)),
      cc: message.cc.map(participant => this.fillParticipantVIP(participant, permissions)),
      bcc: message.bcc.map(participant => this.fillParticipantVIP(participant, permissions))
    };
  }

  private fillParticipantVIP(participant: Participant, permissions: PermissionParticipant[]): Participant {
    return {
      ...participant,
      vip: permissions.some(permission => permission.type === 'vip' && permission.value === participant.address),
      'white-list': permissions.some(permission => permission.type === 'white-list' && permission.value === participant.address),
      'black-list': permissions.some(permission => permission.type === 'black-list' && permission.value === participant.address)
    };
  }
}
