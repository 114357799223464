export interface Participant  {
  id?: string;
  address: string;
  name?: string;
  vip?: boolean;
  'white-list'?: boolean;
  'black-list'?: boolean;
  normalizedName?: string;
}

export namespace Participant {
  export function is(obj : unknown) : obj is Participant {
    return obj && typeof (obj as unknown as Participant).address === 'string';
  }
}