// Types
import { Connection } from '@modules/connections/types/connection';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';
import { MailAppAdvancedFilters } from './mail-app-advanced-filters';
import { CalendarAppAdvancedFilters } from './calendar-app-advanced-filters';
import { TaskingAppAdvancedFilters } from './tasking-app-advanced-filters';
import { NotesAppAdvancedFilters } from './notes-app-advanced-filters';
import { ContactsAppAdvancedFilters } from './contacts-app-advanced-filters';
import { FilesAppAdvancedFilters } from './files-app-advanced-filters';
import { Application } from '@modules/common/types/application';

export class AdvancedSearchState {
  query: string;
  tags: Tag[];
  knots: Knot[];
  connections: Connection[];
  [Application.mail]: MailAppAdvancedFilters;
  [Application.calendar]: CalendarAppAdvancedFilters;
  [Application.tasking]: TaskingAppAdvancedFilters;
  [Application.notes]: NotesAppAdvancedFilters;
  [Application.contacts]: ContactsAppAdvancedFilters;
  [Application.files]: FilesAppAdvancedFilters;
  [Application.kp]: null;
  [Application.settings]: null;
  [Application.admin]: null;
  // ElasticSearch fine tuning
  analyzer: 'standard' | 'english' | 'ngrams_2_7';
  priority: 'title' | 'body' | 'none';
  multiMatchType: 'best_fields' | 'most_fields' | 'cross_fields' | 'phrase';

  constructor(filters?: any) {
    this.query = filters?.query || null;
    this.tags = Array.isArray(filters?.tags) ? filters.tags.map((t: string) => new Tag(t)) : [];
    this.knots = Array.isArray(filters?.knots) ? filters.knots.map((t: string) => new Knot(t)) : [];
    this.connections = Array.isArray(filters?.connections) ? filters.connections.map((t: string) => new Connection(t)) : [];
    this[Application.mail] = new MailAppAdvancedFilters(filters?.mail);
    this[Application.calendar] = new CalendarAppAdvancedFilters(filters?.calendar);
    this[Application.tasking] = new TaskingAppAdvancedFilters(filters?.tasking);
    this[Application.notes] = new NotesAppAdvancedFilters(filters?.notes);
    this[Application.contacts] = new ContactsAppAdvancedFilters(filters?.contacts);
    this[Application.files] = new FilesAppAdvancedFilters(filters?.files);
    this.analyzer = filters?.analyzer;
    this.priority = filters?.priority;
    this.multiMatchType = filters?.multiMatchType;
  }
}
