import { Routes } from '@angular/router';

// Components
import { UsersComponent } from './components/users/users.component';
import { UserComponent } from './components/user/user.component';
import { PlansPlansComponent } from './components/plans/plans/plans.component';
import { PlansSettingsComponent } from './components/plans/settings/settings.component';
import { AdminSMTPSettingsComponent } from '@modules/admin/components/smtp-settings/smtp-settings.component'

export const routes: Routes = [
  { path: 'users', component: UsersComponent },
  { path: 'users/:id', component: UserComponent },
  { path: 'plans', children: [
    { path: 'plans', component: PlansPlansComponent },
    { path: 'config', component: PlansSettingsComponent },
    { path: '', redirectTo: 'default', pathMatch: 'full' },
  ] },
  { path: 'smtp', component: AdminSMTPSettingsComponent },
  { path: '', redirectTo: 'users', pathMatch: 'full' },
];
