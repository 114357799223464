<span class="placeholder" *ngIf="items.length === 0">No Projects found</span>

<div appSelectableList>
  <app-sidebar-item
    *nestedFor="
      let project of items;
      let index = index;
      let depth = depth;
      let expandable = expandable;
      let itemExpanded = expanded;
      stateKey: treeStateKey;
    "
    [title]="project.title"
    [actions]="[{
      icon: 'sapphire-plus',
      template: itemCreateFormTemplate,
      context: { parentId: project.id },
      until: quickFormPopoverClose
    }]"
    [badge]="{ template: badgeTemplate, context: { flagged: project.flagged, pinned: project.pinned } }"
    [iconPadding]="false"
    [depth]="team ? depth + 2 : depth"
    [expandable]="expandable"
    [expanded]="itemExpanded.value"
    [active]="selectedItemsIds | includesBy : project?.id"
    (onExpand)="itemExpanded.next($event)"
    (appClick)="handleClick(project)"
    (appDblClick)="handleDblClick(project)"
    [appProjectContextMenu]="project"
    [appSelectable]="project.id"
    [appSelectablePosition]="index"
    [appSelectableIdentifier]="project.id"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate(project)"
    (appDroppableDrop)="dndDrop($event, project)"

    appDraggable
    [appDraggableData]="items | selectedItemsDragData: selectedItemsIds: project"
    [appDraggablePlaceholder]="placeholder"
    [appDraggablePlaceholderContext]="{ item: project }"
  />
</div>

<ng-template #badgeTemplate let-flagged=flagged let-pinned=pinned>
  <div class="badge-template">
    <mat-icon
      class="badge-template-icon pinned"
      *ngIf="pinned"
      svgIcon="pin-direct"
    />

    <mat-icon
      *ngIf="flagged"
      class="badge-template-icon flagged"
      svgIcon="starred"
    />
  </div>
</ng-template>
