import {Directive, ElementRef, Output, EventEmitter, Input, OnDestroy, NgZone} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

@Directive({
  selector: '[appOutsideClick]'
})
export class OutsideClickDirective implements OnDestroy {

  // Inputs
  @Input() outsideClickExceptSelectors: string;
  @Input() outsideClickExceptElements ?: HTMLElement[];
  @Input() appOutsideClickTrackEnabled = true;

  // Outputs
  @Output() appOutsideClick = new EventEmitter<MouseEvent>();

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) {
    this.ngZone.runOutsideAngular(() =>
      fromEvent(document, 'mousedown')
        .pipe(
          filter((event: MouseEvent) => (
            this.appOutsideClickTrackEnabled &&
            !(
              this.outsideClickExceptSelectors &&
              Array.from(document.querySelectorAll(this.outsideClickExceptSelectors))
                .some(i => i.contains(event.target as HTMLElement))
            ) &&
            !this.elementRef.nativeElement.contains(event.target) &&
            !event?.['path']?.includes(this.elementRef.nativeElement) &&
            !this.outsideClickExceptElements?.some(el => el.contains(event.target as HTMLElement))
          )),
          takeUntil(this.alive)
        )
        .subscribe((event: MouseEvent) => this.ngZone.run(() => this.appOutsideClick.emit(event)))
    );
  }

  /**
   * Directive lifecycle
   */

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}

