// Common
import { Component, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { CalendarsFilters } from '@modules/calendar-app/types/calendars-filters';
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { StateKey } from '@modules/settings/types/state-key';

import { CalendarsListState } from '@modules/calendar-app/types/calendars-list-state';
import { Application } from '@modules/common/types/application';

// Services
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';

// RX
import { takeUntil } from 'rxjs/operators';

// Components
import { BaseSidebarContainersItemComponent } from '@modules/common/components/base-sidebar-containers-item/base-sidebar-containers-item.component';

@Component({
  selector: 'app-side-bar-calendars',
  templateUrl: './side-bar-calendars.component.html',
  styleUrls: ['../../../common/components/base-sidebar-containers-item/base-sidebar-containers-item.component.less', './side-bar-calendars.component.less'],
  animations: [heightAnimation],
})
export class SideBarCalendarsComponent extends BaseSidebarContainersItemComponent<Calendar, CalendarEvent, CalendarsFilters, VirtualFolder, CalendarsListState> implements OnInit {

  application = Application.calendar;
  sidebarSplitViewKey = SidebarSplitViewKey.calendar;
  listStateKey = StateKey.sidebarCalendarsListState;

  constructor(calendarAppStateService: CalendarAppStateService) {
    super(calendarAppStateService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.itemsListState
      .pipe(takeUntil(this.alive))
      .subscribe(state => {
        this.filters = new CalendarsFilters().applyListState(state);
      });
  }
}
