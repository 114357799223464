// Common
import { ESCAPE } from '@angular/cdk/keycodes';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ComponentType,
  ConnectionPositionPair,
  ScrollStrategy,
  ScrollStrategyOptions
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy, Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// RX
import { fromEvent, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, distinct, takeUntil } from 'rxjs/operators';

// Types
import { Bounding } from '@modules/popover/types/bounding';
import { BoundingShift } from '@modules/popover/types/boundingShift';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverAppearance } from '@modules/popover/types/popover-appearance';

const TOLERANCE = 5;

const DEFAULT_FALLBACK_PLACEMENTS: {[key in PopoverPlacement]: PopoverPlacement[]} = {
  left: ['mouseLeft', 'right', 'mouseRight'],
  right: ['mouseRight', 'left', 'mouseLeft'],
  top: ['bottom'],
  bottom: ['top'],
  bottomLeft: ['bottomRight', 'left', 'right'],
  bottomRight: ['bottomLeft', 'right', 'left'],
  topRight: [],
  topLeft: ['bottomLeft'],
  mouseLeft: ['mouseRight'],
  mouseRight: ['mouseLeft'],
  mouseTop: [],
  mouseBottom: [],
  mouseBottomRight: ['mouseBottomLeft'],
  mouseBottomLeft: ['mouseBottomRight'],
  bottomFullWidth: ['topFullWidth', 'bottomLeft', 'bottomRight'],
  topFullWidth: ['bottomFullWidth']
};

@Component({
  selector: 'popover',
  templateUrl: './popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  styleUrls: ['./popover.component.less'],
  providers: [
    { provide: CLOSE_POPOVER, useValue: new Subject<void>() }
  ]
})
export class PopoverComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {

  @ViewChild('overlay', { static: true }) overlay: CdkConnectedOverlay;
  @ViewChild('popoverContainer') popoverContainer: ElementRef;
  @ViewChild('arrow') arrow: ElementRef;
  @ViewChild('contentRef', { read: ViewContainerRef }) contentRef: ViewContainerRef;

  private alive: Subject<void> = new Subject();
  private mouseMoveSubscription: Subscription;
  private eventPageX: number;
  private eventPageY: number;
  private lastOriginX: number;
  private lastOriginY: number;
  private uniqClassName = 'a' + Math.random().toString(36).slice(-5);
  private contentComponentRef: ComponentRef<any>;

  public visible = false;
  public overlayOrigin: CdkOverlayOrigin;
  public scrollStrategy: ScrollStrategy;
  public overlayPosition: ConnectionPositionPair = new ConnectionPositionPair(
    { originX: 'start', originY: 'top' },
    { overlayX: 'center', overlayY: 'top' }
  );
  public staticWidth: number = null;
  public outsideClickExceptSelectors: string;
  public nestedClassName: string[];
  public positionChanged = new Subject<MouseEvent>();

  @Input() contentComponent: ComponentType<any>;
  @Input() contentComponentDelegate: (componentRef: ComponentRef<any>) => void;
  @Input() contentTemplate: TemplateRef<void>;
  @Input() context = {};
  @Input() placement: PopoverPlacement = 'top';
  @Input() innerShadow: boolean;
  @Input() allowedOutsideSelectorsClick: string;
  @Input() allowedOutsideElementsClick ?: HTMLElement[];
  @Input() popoverArrow: boolean;
  @Input() popoverOffsetX = 0;
  @Input() popoverOffsetY = 0;
  @Input() trackMouse: boolean;
  @Input() fallbackPlacements: PopoverPlacement[];
  @Input() originRef: ElementRef;
  @Input() padding: number;
  @Input() parentNestedSelectors: string;
  @Input() appearance: PopoverAppearance;
  @Input() noBorder = false;
  @Input() close: Subject<void>;
  @Input() customStyles: { [key: string]: string };

  @Output() readonly visibleChange = new EventEmitter<boolean>();
  @Output() readonly mouseLeave = new EventEmitter<MouseEvent>();
  @Output() outsideClick = new EventEmitter();
  @Output() error = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private ngZone: NgZone,
    private scrollStrategyOptions: ScrollStrategyOptions,
    private injector: Injector,
    @Self() @Inject(CLOSE_POPOVER) public closePopoverToken,
  ) {
    this.scrollStrategy = this.scrollStrategyOptions.reposition();
  }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    if (this.contentComponent){
      this.contentComponentRef = this.contentRef.createComponent(this.contentComponent, { injector: this.injector });
      this.contentComponentDelegate?.(this.contentComponentRef);
      this.contentComponentRef.changeDetectorRef.detectChanges();
    }

    this.nestedClassName = this.parentNestedSelectors
      ? [...this.parentNestedSelectors.split(','), this.uniqClassName]
      : [this.uniqClassName];

    this.outsideClickExceptSelectors = [
      `.${this.uniqClassName}`,
      this.allowedOutsideSelectorsClick
    ]
      .filter(i => !!i)
      .join(', ');

    this.popoverContainer.nativeElement.dataset.nestedSelectors = this.nestedClassName.join(',');
    this.updatePosition(undefined);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('trackMouse' in changes) {
      this.configureTrackMouse();
    }
  }

  ngOnInit() {
    this.configureTrackMouse();

    this.closePopoverToken
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.close.next();
      });

    this.positionChanged
      .pipe(
        takeUntil(this.alive),
        debounceTime(0)
      )
      .subscribe(event => {
        if (event) {
          this.updatePosition(event);
        }
      });
  }

  ngOnDestroy() {
    this.contentComponentRef?.destroy();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleOutsideClick() {
      this.outsideClick.emit();
  }

  handleMouseLeave(event: MouseEvent) {
    this.mouseLeave.emit(event);
  }

  setOverlayOrigin(origin: CdkOverlayOrigin): void {
    this.overlayOrigin = origin;
  }

  show(event: MouseEvent): void {
    this.visible = true;
    this.visibleChange.emit(true);

    if (this.placement === 'bottomFullWidth' || this.placement === 'topFullWidth') {
      const originBounding = this.getBounding(this.originRef.nativeElement);
      this.staticWidth = originBounding.width - 2 * this.padding;
    } else {
      this.staticWidth = null;
    }

    if (event) {
      this.eventPageX = event.pageX;
      this.eventPageY = event.pageY;
    }
  }

  hide(): void {
    this.visibleChange.emit(false);
    this.visible = false;
  }

  private updatePosition(event?: MouseEvent) {
    const eventX = event?.pageX || this.eventPageX;
    const eventY = event?.pageY || this.eventPageY;

    if (
      !this.overlay ||
      !this.overlay.overlayRef ||
      !this.popoverContainer ||
      (
        this.lastOriginX !== undefined &&
        this.lastOriginY !== undefined &&
        this.lastOriginX === eventX &&
        this.lastOriginY === eventY
      )
    ) { return; }

    this.lastOriginX = eventX;
    this.lastOriginY = eventY;

    const originBounding = this.getBounding(this.originRef.nativeElement);

    // Sometimes on slow machines it happens that origin component is already destroyed
    // somethimes it happens because of scroll inertia effect
    // and no signal emitted. This code is just to reasure
    // if (!document.contains(this.originRef.nativeElement)) too slow
    if (originBounding.height === 0 || originBounding.width === 0) {
      this.error.emit();
    }

    const contentBounding = this.getBounding(this.popoverContainer.nativeElement);

    const [contentBoundingShift, arrowBoundingShift] = this.reposition(
      this.placement,
      contentBounding,
      originBounding,
      eventX,
      eventY,
      this.fallbackPlacements || DEFAULT_FALLBACK_PLACEMENTS[this.placement]
    );

    this.applyBoundingShift(this.popoverContainer.nativeElement, contentBoundingShift);
    this.applyBoundingShift(this.arrow?.nativeElement, arrowBoundingShift);
  }

  handleKeydown(event) {
    if (event.keyCode === ESCAPE) {
      this.outsideClick.emit();
    }
  }

  subscribeToMouseMove() {
    if (this.mouseMoveSubscription) { return; }

    this.ngZone.runOutsideAngular(() => {
      this.mouseMoveSubscription = fromEvent(document, 'mousemove')
        .pipe(
          takeUntil(this.alive),
          distinct((event: MouseEvent) => `${event.pageY}x${event.pageX}`)
        )
        .subscribe((event: MouseEvent) => {
          this.positionChanged.next(event);
        });
    });
  }

  unsubscribeFromMouseMove() {
    if (this.mouseMoveSubscription) {
      this.mouseMoveSubscription.unsubscribe();
    }
  }

  configureTrackMouse() {
    if (this.trackMouse) {
      this.subscribeToMouseMove();
    } else {
      this.unsubscribeFromMouseMove();
    }
  }

  /**
   * Helpers
   */

  reposition(
    placement: PopoverPlacement,
    content: Bounding,
    origin: Bounding,
    eventX: number,
    eventY: number,
    fallbackPlacements: PopoverPlacement[],
  ): [BoundingShift, BoundingShift] {
    const [fallbackPlacement, ...nextFallbackPlacements] = fallbackPlacements;

    const ARROW_WIDTH = this.popoverArrow ? 30 : 0;
    const HALF_ARROW_WIDTH = this.popoverArrow ? 15 : 0;

    const originHalfHeight = origin.height / 2;
    const eventXShift = eventX ? eventX - origin.left : 0;
    const eventYShift = eventY ? eventY - origin.top : 0;
    const contentFullHeight = HALF_ARROW_WIDTH + TOLERANCE + content.height;
    const contentFullWidth = HALF_ARROW_WIDTH + TOLERANCE + content.width;

    switch (placement) {
      case 'right':
        if (fallbackPlacement && origin.right < contentFullWidth) { break; }

        return [
          {
            left: origin.left + origin.width + HALF_ARROW_WIDTH + TOLERANCE,
            top: this.fitVertical(content, origin.top - content.height / 2 + origin.height / 2)
          },
          {
            left: origin.left + origin.width + TOLERANCE,
            top: origin.top + originHalfHeight - HALF_ARROW_WIDTH
          }
        ];
      case 'mouseRight':
        if (fallbackPlacement && (!eventX || !eventY)) { break; }
        if (fallbackPlacement && origin.right + origin.width - eventXShift < contentFullWidth) { break; }

        return [
          {
            left: origin.left + eventXShift + HALF_ARROW_WIDTH + TOLERANCE,
            top: this.fitVertical(content, origin.top + eventYShift - content.height / 2)
          },
          {
            left: origin.left + eventXShift + TOLERANCE,
            top: origin.top + eventYShift - HALF_ARROW_WIDTH
          }
        ];
      case 'left':
        if (fallbackPlacement && origin.left < contentFullWidth) { break; }

        return [
          {
            left: origin.left - content.width - HALF_ARROW_WIDTH - TOLERANCE,
            top: this.fitVertical(content, origin.top - content.height / 2 + origin.height / 2)
          },
          {
            left: origin.left - ARROW_WIDTH - TOLERANCE,
            top: origin.top + originHalfHeight - HALF_ARROW_WIDTH
          }
        ];
      case 'mouseLeft':
        if (fallbackPlacement && (!eventX || !eventY)) { break; }
        if (fallbackPlacement && eventX < contentFullWidth) { break; }

        return [
          {
            left: eventXShift + origin.left - content.width - HALF_ARROW_WIDTH - TOLERANCE,
            top: this.fitVertical(content, origin.top + eventYShift - content.height / 2)
          },
          {
            left: eventXShift + origin.left - ARROW_WIDTH - TOLERANCE,
            top: origin.top + eventYShift - HALF_ARROW_WIDTH
          }
        ];
      case 'top':
        if (fallbackPlacement && origin.top < contentFullHeight) { break; }

        return [
          {
            left: this.fitHorizontal(content, origin.left - content.width / 2 + origin.width / 2),
            top: origin.top - content.height - HALF_ARROW_WIDTH,
          },
          {
            left: origin.left + origin.width / 2 - HALF_ARROW_WIDTH,
            top: origin.top - ARROW_WIDTH
          }
        ];
      case 'topLeft':
        if (fallbackPlacement && origin.top < contentFullHeight) { break; }
        if (fallbackPlacement && origin.right + origin.width < contentFullWidth) { break; }

        return [
          {
            left: origin.left,
            top: Math.max(0, origin.top - content.height - HALF_ARROW_WIDTH),
            maxHeight: origin.top - TOLERANCE * 2 - this.popoverOffsetY
          },
          {
            left: origin.left + origin.width / 2 - HALF_ARROW_WIDTH,
            top: origin.top - ARROW_WIDTH
          }
        ];
      case 'topRight':
        if (fallbackPlacement && origin.top < contentFullHeight) { break; }
        if (fallbackPlacement && origin.left + origin.width < contentFullWidth) { break; }

        return [
          {
            left: origin.left + origin.width - content.width,
            top: origin.top - content.height - HALF_ARROW_WIDTH,
            maxHeight: origin.top - TOLERANCE * 2 - this.popoverOffsetY
          },
          {
            left: origin.left + origin.width / 2 - HALF_ARROW_WIDTH,
            top: origin.top - ARROW_WIDTH
          }
        ];
      case 'bottom':
        if (fallbackPlacement && origin.bottom < contentFullHeight) { break; }

        return [
          {
            left: this.fitHorizontal(content, origin.left - content.width / 2 + origin.width / 2),
            top: origin.top + origin.height + HALF_ARROW_WIDTH + TOLERANCE,
            maxHeight: origin.bottom - TOLERANCE * 2 - this.popoverOffsetY
          },
          {
            left: origin.left + origin.width / 2 - HALF_ARROW_WIDTH,
            top: origin.top + origin.height + TOLERANCE
          }
        ];
      case 'bottomLeft':
        if (fallbackPlacement && origin.bottom < contentFullHeight) { break; }
        if (fallbackPlacement && origin.right + origin.width < contentFullWidth) { break; }

        return [
          {
            left: origin.left,
            top: origin.top + HALF_ARROW_WIDTH + TOLERANCE + origin.height,
            maxHeight: origin.bottom - TOLERANCE * 2 - this.popoverOffsetY
          },
          {
            left: origin.left + origin.width / 2 - HALF_ARROW_WIDTH,
            top: origin.top + origin.height + TOLERANCE
          }
        ];
      case 'bottomRight':
        if (fallbackPlacement && origin.bottom < contentFullHeight) { break; }
        if (fallbackPlacement && origin.left + origin.width < contentFullWidth) { break; }

        return [
          {
            left: origin.left + origin.width - content.width,
            top: origin.top + HALF_ARROW_WIDTH + TOLERANCE + origin.height,
            maxHeight: origin.bottom - TOLERANCE * 2 - this.popoverOffsetY
          },
          {
            left: origin.left + origin.width / 2 - HALF_ARROW_WIDTH,
            top: origin.top + origin.height + TOLERANCE
          }
        ];
      case 'mouseBottomRight':
        if (fallbackPlacement && (!eventX || !eventY)) { break; }
        if (fallbackPlacement && origin.right + origin.width - eventXShift < contentFullWidth) { break; }

        return [
          {
            left: origin.left + eventXShift + TOLERANCE,
            top: this.fitVertical(content, origin.top + eventYShift)
          },
          {
            left: 0,
            top: 0
          }
        ];
      case 'mouseBottomLeft':
        if (fallbackPlacement && (!eventX || !eventY)) { break; }
        if (fallbackPlacement && eventX < contentFullWidth) { break; }

        return [
          {
            left: eventXShift + origin.left - content.width - HALF_ARROW_WIDTH - TOLERANCE,
            top: this.fitVertical(content, origin.top + eventYShift)
          },
          {
            left: 0,
            top: 0
          }
        ];
      case 'bottomFullWidth':
        if (fallbackPlacement && origin.bottom < contentFullHeight) { break; }

        return [
          {
            left: origin.left + this.padding,
            top: origin.top + HALF_ARROW_WIDTH + TOLERANCE + origin.height,
          },
          {
            left: origin.left + origin.width / 2 - HALF_ARROW_WIDTH,
            top: origin.top + origin.height + TOLERANCE
          }
        ];
      case 'topFullWidth':
        if (fallbackPlacement && origin.top < contentFullHeight) { break; }

        return [
          {
            left: origin.left + this.padding,
            top: origin.top - content.height - HALF_ARROW_WIDTH - TOLERANCE,
          },
          {
            left: origin.left + origin.width / 2 - HALF_ARROW_WIDTH,
            top: origin.top - ARROW_WIDTH - TOLERANCE
          }
        ];
      case 'mouseTop':
      case 'mouseBottom':
        this.staticWidth = null;
        return [{ left: 0, top: 0 }, { left: 0, top: 0 }];
      default:
        checkExhaustiveness(placement);
    }

    return this.reposition(fallbackPlacement, content, origin, eventX, eventY, nextFallbackPlacements);
  }

  getBounding(element: HTMLElement): Bounding {
    if (!element) {
      return {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: 0,
        width: 0,
      };
    }

    const nativeBounding = element.getBoundingClientRect();

    return {
      top: nativeBounding.top,
      bottom: window.innerHeight - nativeBounding.top - nativeBounding.height,
      left: nativeBounding.left,
      right: window.innerWidth - nativeBounding.left - nativeBounding.width,
      height: nativeBounding.height,
      width: nativeBounding.width,
    };
  }

  applyBoundingShift(element: HTMLElement, bounding: BoundingShift) {
    if (!element) { return; }

    this.renderer.setStyle(element, 'top', `${ bounding.top + this.popoverOffsetY }px`);
    this.renderer.setStyle(element, 'left', `${ bounding.left + this.popoverOffsetX }px`);

    bounding.maxHeight && this.renderer.setStyle(element, 'max-height', `${ bounding.maxHeight }px`);
  }

  fitVertical(content: Bounding, y: number) {
    return Math.max(
      Math.min(
        document.body.clientHeight - content.height - TOLERANCE,
        y
      ),
      TOLERANCE
    );
  }

  fitHorizontal(content: Bounding, x: number) {
    return Math.max(
      Math.min(
        document.body.clientWidth - content.width - TOLERANCE,
        x
      ),
      TOLERANCE
    );
  }
}
