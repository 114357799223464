// Common
import { Injectable, Optional} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Rx
import { Observable } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Types
import { Like } from '@modules/common/types/like';
import { Calendar } from '../types/calendar';
import { CalendarsFilters } from '../types/calendars-filters';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StitchType } from '@modules/common/types/stitch-type';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';

// Env
import { environment } from '@environment';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { NestedService } from '@modules/common/types/nested-service.interface';

@Injectable()
export class CalendarsService extends BaseStitchService<Calendar, CalendarsFilters> implements CalendarEventsService, NestedService {
  protected url = environment.baseUrl + '/api/calendar/calendars/';
  protected stitchType = StitchType.calendar;
  protected filtersConstructor = CalendarsFilters;

  constructor(
    protected http: HttpClient,
    protected toasterService: ToasterService,
    @Optional() linkedInfoService: LinkedInfoService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  /**
   * Methods
   */

  public bunchUpdate(
    filters: Partial<CalendarsFilters>,
    changes: BunchUpdateOptions & Partial<Pick<Calendar, 'parentId'>>,
    config: FeedbackConfig
  ): Observable<void> {
    return super.bunchUpdate(filters, changes, config);
  }

  create(
    calendarInstance: Calendar,
    { emit, toast, message }: FeedbackConfig = { emit: true },
  ): Observable<Calendar> {
    return this.http.post<{ calendar: Calendar, success: boolean }>(
      `${environment.baseUrl}/api/calendar/calendars`,
      calendarInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || `Calendar created.` });
        }),
        map(({ calendar }) => new Calendar(calendar)),
        switchMap(calendar => this.processKnowledgeItems({
          linkedInfo: calendarInstance.linkedInfo,
          knots: calendarInstance.knots,
          tags: calendarInstance.tags
        }, calendar)),
        switchMap(calendar => this.processUploads(calendarInstance.uploads, calendar, { other: true, parentFolderName: 'Calendar App' })),
        catchError(error => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  update(
    calendarInstance: Calendar,
    { emit, displayToast, toastMessage }: FeedbackConfig = { emit: true }
  ): Observable<Calendar> {
    return this.http.put<{ item: Calendar, success: boolean }>
    (environment.baseUrl + '/api/calendar/calendars/' + calendarInstance.id, calendarInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'Calendar updated' });
          }
        }),
        map(({ item }) => item),
        catchError(error => this.handleObserverError(error)),
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime })
      .pipe(
        map(({ items }) => items.map(calendar => calendar.asAngularCalendarEvent()))
      );
  }

  getAutocompleteSuggestions(inputFilters: Like<CalendarsFilters> = {}): AutocompleteFactory<Calendar> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<CalendarsFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title?.trim()) {
        filters.query = title.trim();
        filters.esAnalyzer = 'ngrams_2_7';
        filters.esPriority = 'title';
        filters.esMultiMatchType = 'best_fields';
      }

      return this.search(filters)
        .pipe(
          map((response: BaseSearchResponse<Calendar>) => response.items.map(calendar => ({
            title: calendar.title,
            value: calendar.id,
            source: calendar
          }))),
        );
    };
  }

  createInstance(item): Calendar {
    return new Calendar(item);
  }

  validateNested(id: string, parentId: string) {
    return this.http.get<{ deep: boolean, circular: boolean }>(
      `${ environment.baseUrl }/api/calendar/calendars/validate-nesting/${ id }/${ parentId }`
    )
      .pipe(
        catchError(error => this.handleObserverError(error))
      );
  }
}
