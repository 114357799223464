<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <div
    class="form-heading"
  >
    <app-autocomplete-multiple-input
      appearance="sapphire"
      title="To:"
      placeholder="Enter Name"
      [suggestions]="contactsSuggestions"
      valueKey="address"
      [inputFormControl]="form.controls.to"
      [itemTemplate]="contactTemplate"
      [containerStyles]="{ padding: '0 5px' }"
      [noBorder]="true"
      [useInputText]="true"
      [useInputTextGetValue]="useInputTextGetValue"
      (keyup.enter)="$event.stopPropagation()"
    />

    @if(!inline) {
      <mat-icon
        class="form-heading-icon"
        svgIcon="sapphire-chevron-down"
        (click)="close.emit()"
      />
    }
  </div>

  <app-input
    #titleInput
    class="form-heading-input"
    appearance="sapphire-inline"
    size="xs"
    placeholder="Subject"
    [inputFormControl]="form.controls.subject"
  />

  <div class="form-divider"></div>

  <app-textarea
    [inputFormControl]="form.controls.bodyHtml"
    appearance="sapphire-inline"
    [rows]="3"
    placeholder="Text"
    [invertedColor]="true"
    [resize]="false"
  />

  <div class="form-divider"></div>

  <div class="form-buttons">
    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="More options"
        (click)="clickMore()"
      />
    }

    <div class="form-buttons-filler"></div>

    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="Cancel"
        (click)="!saveInProgress && cancel()"
      />
    }

    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="Add"
      (click)="!saveInProgress && submit()"
    />
  </div>
</div>

<ng-template #contactTemplate let-contact="item">
  <div class="contact">
    <div class="contact-header">
      <app-user-avatar
        [size]="24"
        [contact]="{ title: contact.name, email: contact.address }"
      />
      <span class="contact-header-title">{{ contact.name }}</span>
    </div>
    <span class="contact-description">{{ contact.address }}</span>
  </div>
</ng-template>
