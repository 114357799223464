// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Participant } from '../types/participant';

@Pipe({
  standalone: true,
  name: 'formatParticipant'
})
export class FormatParticipantPipe implements PipeTransform {

  transform(participant: Participant, html?: boolean): any {
    if (!participant) { return ''; }

    if (!participant.name) {
      return participant.address;
    }

    return `${participant.name}${html ? '&lt;' : '<'}${ participant.address}${html ? '&gt;' : '>'}`;
  }
}
