// Common
import { ElementRef, Injectable, NgZone } from '@angular/core';

// RX
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

// Components
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PopoverComponent } from '../components/popover/popover.component';
import { PopoverConfig } from '../types/config';


@Injectable()
export class PopoverService {

  constructor(
    private overlay: Overlay,
    private ngZone: NgZone,
  ) { }

  create(
    originRef: ElementRef,
    config: PopoverConfig,
    originEvent?: MouseEvent,
    nestedSelectors?: string
  ) {
    const overlayRef = this.overlay.create();
    const popoverRef = overlayRef.attach(
      new ComponentPortal(PopoverComponent, undefined, config.injector)
    );
    const close = new Subject<void>();
    const closeFn = () => {
      close.next();
      close.complete();
    };

    popoverRef.instance.appearance = config.appearance;

    // Popover Close options
    popoverRef.instance.error
      .pipe(takeUntil(close))
      .subscribe(closeFn);

    if (config.showUntil) {
      config.showUntil
        .pipe(takeUntil(close))
        .subscribe(closeFn);
    }

    if (
      config.trigger === 'click' ||
      config.trigger === 'contextmenu' ||
      config.closeOnOutsideClick
    ) {
      popoverRef.instance.outsideClick
        .pipe(takeUntil(close))
        .subscribe(closeFn);
    }

    config.trigger === 'hover' && this.ngZone.runOutsideAngular(() => {
      merge(
        merge(
          popoverRef.instance.mouseLeave,
          fromEvent(document, 'mousemove')
        )
          .pipe(
            filter((event: MouseEvent) => {
              const rect = originRef.nativeElement.getBoundingClientRect();
              const tolerance = 5;
              return (
                event.pageX > rect.left + rect.width + tolerance ||
                event.pageX < rect.left - tolerance ||
                event.pageY > rect.top + rect.height + tolerance ||
                event.pageY < rect.top - tolerance
              ) && (
                !popoverRef.instance.popoverContainer ||
                !popoverRef.instance.popoverContainer.nativeElement.contains(event['toElement'])
              );
            })
          ),
        fromEvent(originRef.nativeElement, 'click')
      )
        .pipe(takeUntil(close))
        .subscribe(closeFn);
    });

    // This is have to be after all subscribes, so all takeUntils called properly
    close
      .pipe(take(1))
      .subscribe(() => {
        popoverRef.instance.hide();
        popoverRef.destroy();
        overlayRef.dispose();
      });

    popoverRef.instance.contentTemplate = config.template;
    popoverRef.instance.contentComponent = config.component;
    popoverRef.instance.contentComponentDelegate = config.componentDelegate,
    popoverRef.instance.context = config.context;
    popoverRef.instance.placement = config.placement;
    popoverRef.instance.popoverArrow = config.arrow;
    popoverRef.instance.innerShadow = config.innerShadow;
    popoverRef.instance.trackMouse = config.trackMouse;
    popoverRef.instance.popoverOffsetX = config.popoverOffsetX || 0;
    popoverRef.instance.popoverOffsetY = config.popoverOffsetY || 0;
    popoverRef.instance.allowedOutsideElementsClick = config.allowedOutsideElements;
    popoverRef.instance.fallbackPlacements = config.fallbackPlacements;
    popoverRef.instance.padding = config.padding;
    popoverRef.instance.originRef = originRef;
    popoverRef.instance.parentNestedSelectors = nestedSelectors;
    popoverRef.instance.noBorder = config.noBorder;
    popoverRef.instance.close = close;
    popoverRef.instance.customStyles = config.customStyles;

    config.visibleChange && popoverRef.instance.visibleChange
      .pipe(takeUntil(close))
      .subscribe((visible: boolean) => config.visibleChange.next(visible));

    popoverRef.instance.setOverlayOrigin({ elementRef: originRef });
    popoverRef.instance.show(originEvent);
  }
}
