// Common
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// Types
import { Connection } from '@modules/connections/types/connection';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.less']
})
export class SearchInputComponent implements OnInit, OnDestroy {

  // ViewChild
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  // Public
  public popoverClose = new Subject<void>();
  public popoverWidth = 0;
  public knowledgeItems: { item: (Knot | Tag | Connection), type: string }[];
  public restKnowledgeItemsCount = 0;
  public query = '';

  // Private
  private alive = new Subject<void>();

  constructor(
    private searchService: AdvancedSearchService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.searchService.getState()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(({ query, knots, tags, connections }) => {
        this.query = query;

        this.knowledgeItems = [
          ...(knots.map(item => ({ item, type: 'Knot' }))),
          ...(tags.map(item => ({ item, type: 'Tag' }))),
          ...(connections.map(item => ({ item, type: 'Connection' })))
        ]
          .slice(0, 3);
        this.restKnowledgeItemsCount = Math.max(0, knots.length + tags.length + connections.length - 3);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleOpen() {
    this.popoverWidth = this.searchInput.nativeElement.offsetWidth;
  }

  handleSearch() {
    this.popoverClose.next();
  }

  handleCancel() {
    this.popoverClose.next();
  }
}
