// Common
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'

// Modules
import { FormControlsModule } from "@modules/form-controls/form-controls.module";

// Rx
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators'
import { of, Subject } from 'rxjs'

// Types
import { AdminSettingsFormGroup, Settings } from '@modules/admin/types/settings'

// Services
import { SettingsService } from '@modules/admin/services/settings.service'

@Component({
  selector: 'app-admin-smtp-settings',
  templateUrl: './smtp-settings.component.html',
  styleUrls: ['./smtp-settings.component.less'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormControlsModule]
})

export class AdminSMTPSettingsComponent implements OnInit, OnDestroy {
  public form: AdminSettingsFormGroup;

  private readonly alive = new Subject<void>();

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.settingsService.getSettings()
      .pipe(
        tap((settings) => this.form = settings.asFormGroup()),
        tap(() => this.cd.markForCheck()),
        switchMap(() => this.form.valueChanges),
        debounceTime(500),
        switchMap(() => {
          if (!this.form.valid) { return of(); }

          return this.settingsService.update(Settings.fromFormGroup(this.form));
        }),
        takeUntil(this.alive)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

}
