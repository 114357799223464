<div
  #searchInput
  class="input"
  (click)="handleOpen()"

  stchPopover
  stchPopoverPlacement="bottom"
  stchPopoverTrigger="click"
  [stchPopoverOffsetY]="-42"
  [stchPopoverContent]="searchPopover"
  [stchPopoverFallbackPlacements]="[]"
  [stchPopoverShowUntil]="popoverClose"
>
  <ng-container *ngFor="let item of knowledgeItems">
    <app-knot
      *ngIf="item.type === 'Knot'"
      class="input-knowledge-item"
      [knot]="item.item"
      [withControls]="false"
    ></app-knot>

    <app-tag
      *ngIf="item.type === 'Tag'"
      class="input-knowledge-item"
      [tag]="item.item"
      [withControls]="false"
    ></app-tag>

    <app-connection
      *ngIf="item.type === 'Connection'"
      class="input-knowledge-item"
      [connection]="item.item"
      [withControls]="false"
    ></app-connection>  
  </ng-container>

  <div
    *ngIf="restKnowledgeItemsCount"  
    class="input-more input-knowledge-item"
  >
    +{{ restKnowledgeItemsCount }}
  </div>

  <mat-icon
    *ngIf="!query"
    svgIcon="search-small"
    class="search-icon"
  ></mat-icon>

  <div class="query">{{ query || 'Search' }}</div>
</div>

<ng-template #searchPopover>  
  <app-search-popover
    [width]="popoverWidth"
    (onSearch)="handleSearch()"
    (onCancel)="handleCancel()"
  ></app-search-popover>
</ng-template>
