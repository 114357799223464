// Common
import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

// Modules
import { CommentsModule } from '@modules/comments/comments.module';
import { CommonModule } from '@modules/common/common.module';
import { ConnectionsModule } from '@modules/connections/connections.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { KnotsModule } from '@modules/knots/knots.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PopoverModule } from '@modules/popover/popover.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TagsModule } from '@modules/tags/tags.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { FileFormComponent } from './components/file-form/file-form.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { FileSharedComponent } from './components/file-shared/file-shared.component';
import { FilesListComponent } from './components/files-list/files-list.component';
import { FilesListContainerComponent } from './components/list-container/list-container.component';
import { FilesListContextMenuComponent } from './components/files-list-context-menu/files-list-context-menu.component';
import { FilesMainViewComponent } from './components/files-main-view/files-main-view.component';
import { FilesSideBarComponent } from '@modules/files/components/side-bar/side-bar.component';
import { FolderFormComponent } from './components/folder-form/folder-form.component';
import { FolderSectionsComponent } from './components/folder-sections/folder-sections.component';
import { FolderSharedComponent } from './components/folder-shared/folder-shared.component';
import { FoldersListComponent } from './components/folders-list/folders-list.component';
import { FoldersListContextMenuComponent } from './components/folders-list-context-menu/folders-list-context-menu.component';
import { PDFPreviewComponent } from './components/file-preview/pdf-preview/pdf-preview.component';
import { SideBarFoldersComponent } from './components/side-bar-folders/side-bar-folders.component';
import { SideBarFoldersTreeComponent } from './components/side-bar-folders-tree/side-bar-folders-tree.component';
import { SidebarContextMenuComponent } from './components/sidebar-context-menu/sidebar-context-menu.component';

// Directives
import { FilesListContextMenuDirective } from './directives/files-list-context-menu.directive';
import { FoldersListContextMenuDirective } from './directives/folders-list-context-menu.directive';
import { SidebarContextMenuDirective } from './directives/sidebar-context-menu.directive';

// Pipes
import { TitleByFilesFilterPipe } from '@modules/files/pipes/title-by-files-filter.pipe';

@NgModule({
  imports: [
    AngularCommonModule,
    CommentsModule,
    CommonModule,
    ConnectionsModule,
    DragNDropModule,
    ElementsModule,
    FormControlsModule,
    FormsModule,
    KnotsModule,
    LinkedInfoModule,
    MatIconModule,
    MatTooltipModule,
    PdfViewerModule,
    PopoverModule,
    ReactiveFormsModule,
    ScrollModule,
    ScrollingModule,
    TagsModule,
    UtilsModule
  ],
  declarations: [
    // Components
    FileFormComponent,
    FilePreviewComponent,
    FileSharedComponent,
    FilesListComponent,
    FilesListContainerComponent,
    FilesListContextMenuComponent,
    FilesMainViewComponent,
    FilesSideBarComponent,
    FolderFormComponent,
    FolderSectionsComponent,
    FolderSharedComponent,
    FoldersListComponent,
    FoldersListContextMenuComponent,
    PDFPreviewComponent,
    SideBarFoldersComponent,
    SideBarFoldersTreeComponent,
    SidebarContextMenuComponent,
    // Directives
    FoldersListContextMenuDirective,
    FilesListContextMenuDirective,
    SidebarContextMenuDirective,
    // Pipes
    TitleByFilesFilterPipe,
  ],
  exports: [
    // Components
    FileFormComponent,
    FilesListComponent,
    FilesListContainerComponent,
    FilesListContextMenuComponent,
    FilesMainViewComponent,
    FilesSideBarComponent,
    FolderFormComponent,
    FoldersListComponent,
    FoldersListContextMenuComponent,
    // Directives
    FoldersListContextMenuDirective,
    FilesListContextMenuDirective,
    // Pipes
    TitleByFilesFilterPipe,
  ],
  providers: []
})
export class FilesModule { }
