// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// Rx
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { StitchService } from '@modules/common/services/stitch.service';

// Types
import { AutomationRule } from '../types/automation-rule';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { StitchType } from '@modules/common/types/stitch-type';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable()
export class AutomationRulesService {
  private refreshRequired = new BehaviorSubject<void>(null);

  constructor (
    protected http: HttpClient,
    protected toaster: ToasterService,
    protected stitchService: StitchService,
  ) { }

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  public forceRefresh() {
    this.refreshRequired.next();
  }

  getRefreshRequired(): Observable<void> {
    return this.refreshRequired.asObservable();
  }

  /**
   * Methods
   */

  listAll(stitchTypes: StitchType[]): Observable<AutomationRule[]> {
    return this.http.get<{ items: object[] }>(
      `${environment.baseUrl}/api/automation-rules/${stitchTypes[0]}`,
      {
        params: {
          'stitch_types[]': stitchTypes
        }
      }
    )
      .pipe(
        map(({ items }) => items.map(item => new AutomationRule(item)))
      );
  }

  create(
    ruleInstance: AutomationRule,
    { emit, displayToast, toastMessage }: FeedbackConfig = { emit: true }
  ): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/automation-rules/${ruleInstance.stitchType}`,
      ruleInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            if (emit) {
              this.forceRefresh();
            }
            if (displayToast) {
              this.toaster.show({ text: toastMessage || `Rule created.` });
            }
          }
        }),
        map(({ success }) => success),
        catchError((error: HttpErrorResponse) => {
          this.toaster.show({ text: error?.error?.error || error?.message });
          return throwError(error);
        })
      );
  }

  @warmUpObservable
  update(
    ruleInstance: AutomationRule,
    emit: boolean = true,
    displayToast?: boolean,
    toastMessage?: string
  ): Observable<boolean> {
    return this.http.put<{ success: boolean }>(
      `${environment.baseUrl}/api/automation-rules/${ruleInstance.stitchType}/${ruleInstance.id}`, ruleInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            displayToast && this.toaster.show({ text: toastMessage || 'Rule updated' });
          }
        }),
        map(({ success }) => success)
      );
  }

  @warmUpObservable
  delete(
    ruleInstance: AutomationRule,
    emitChanges = true,
    displayToast = true,
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      `${environment.baseUrl}/api/automation-rules/${ruleInstance.stitchType}/${ruleInstance.id}`
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emitChanges && this.forceRefresh();

          displayToast && this.toaster.show({ text: 'Rule successfully deleted' });
        })
      );
  }
}
