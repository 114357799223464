// Common
import { Component, Input, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';

// RX
import { merge, of, Subject } from 'rxjs';
import { filter, switchMap, map, tap, takeUntil, pairwise, startWith } from 'rxjs/operators';

// Types
import { Task } from '@modules/tasks/types/task';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { TaskingAppSettingsService } from '@modules/tasks/services/settings.service';

@Component({
  selector: 'app-task-form-subtasks',
  templateUrl: './task-form-subtasks.component.html',
  styleUrls: ['./task-form-subtasks.component.less']
})
export class TaskFormSubtasksComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() task: Task;
  @Input() filters: TasksFilters;
  @Input() triggerRefresh: Subject<void>;

  // Public
  public subtasks: Task[] = [];
  public title = '';
  public subtaskInputFocused = false;
  public showIssueKey = false;

  // Private
  private alive: Subject<void> = new Subject();
  private tasksUpdated: Subject<string> = new Subject();
  private triggerRefreshUpdated = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private tasksService: TasksService,
    private settingsService: TaskingAppSettingsService,
  ) { }

  ngOnInit() {
    merge(
      this.tasksService.getRefreshRequired(),
      this.triggerRefreshUpdated
        .pipe(switchMap(() => this.triggerRefresh || of()))
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.tasksUpdated.next(null);
      });

    this.tasksUpdated
      .pipe(
        startWith(this.task?.id),
        pairwise(),
        tap(([id1, id2]) => id1 === id2 ? null : this.subtasks = []),
        filter(() => !!this.task && !!this.task.id),
        switchMap(() => this.tasksService.search({ parentsIds: [this.task.id], ...this.filters, limit: 1000 })),
        map(({ items }) => items)
      )
      .subscribe((tasks: Task[]) => {
        this.subtasks = tasks;
        // TODO Implement sorting option by order
        // this.subtasks = tasks.sort((a, b) => (a.position > b.position) ? 1 : -1);
      });

    this.settingsService.listAll()
      .pipe(takeUntil(this.alive))
      .subscribe(({ issueKeyEnabled }) => this.showIssueKey = issueKeyEnabled);

    this.tasksUpdated.next(null);
    this.triggerRefreshUpdated.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('task' in changes || 'filters' in changes) {
      this.tasksUpdated.next(this.task?.id);
    }

    if ('triggerRefresh' in changes) {
      this.triggerRefreshUpdated.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleEnterNew() {
    if (
      !this.task ||
      !this.task.id ||
      !this.title ||
      this.title.trim() === ''
    ) { return; }

    const newSubtask = new Task({
      parentId: this.task.id,
      projectId: this.task.projectId,
      position: this.subtasks.length,
      title: this.title
    });

    this.tasksService.create(newSubtask, { emit: false })
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.tasksUpdated.next(null);
      });

    this.title = '';
  }

  handleComplete(item: Task) {
    item.completed = !item.completed;
    this.tasksService.update(item, { emit: false })
      .pipe(takeUntil(this.alive));
  }
}
