<ng-container *ngIf="!multiple">
  <app-context-menu-item
    title="Open"
    [close]="true"
    (execute)="handleOpen()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="message?.pinned ? 'Unpin' : 'Pin'"
    [close]="true"
    (execute)="pin()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="message?.flagged ? 'Unflag' : 'Flag'"
    [close]="true"
    (execute)="flag()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="message?.unread ? 'Mark as Read' : 'Mark as Unread'"
    [close]="true"
    (execute)="read()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="!(message?.draft || message?.sent)"
    [title]="message?.bulk ? 'Not Bulk' : 'Move to Bulk'"
    [close]="true"
    (execute)="bulk()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="!(message?.draft || message?.sent)"
    [title]="message?.spam ? 'Not Spam' : 'Move to Spam'"
    [close]="true"
    (execute)="spam()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    *ngIf="message?.scheduled"
    title="Unschedule"
    (execute)="unschedule()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="message?.scheduled"
    title="Send Now"
    (execute)="sendNow()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Snooze"
  >
    <app-postpone-context-menu
      [items]="[message]"
      (onSelect)="postpone($event, 'snooze')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-item
    title="Follow up"
  >
    <app-postpone-context-menu
      [items]="[message]"
      appearance="followUp"
      (onSelect)="postpone($event, 'followUp')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Reply"
    [close]="true"
    (execute)="forward('reply')"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Reply To All"
    [close]="true"
    (execute)="forward('replyAll')"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Forward"
    [close]="true"
    (execute)="forward('forward')"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Forward As Attachment"
    [close]="true"
    (execute)="forward('forwardAsAttachment')"
  ></app-context-menu-item>

  <app-context-menu-divider *ngIf="selectable"></app-context-menu-divider>

  <app-context-menu-item
    *ngIf="selectable"
    title="Select All"
    [close]="true"
    (execute)="selectAll()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Open in Modal"
    [close]="true"
    (execute)="openInModal()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Open in Dynamic Panel"
    [close]="true"
    (execute)="openInDP()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Copy To"
  >
    <app-move-to
      appearance="copy"
      [items]="[message]"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Move To"
  >
    <app-move-to
      appearance="move"
      [items]="[message]"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Stitch"
  >
    <app-stitch-to
      [stitchItems]="[message]"
      (close)="close()"
    ></app-stitch-to>
  </app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Add Knots"
  >
    <app-add-knots
      [stitchItems]="[message]"
      (close)="close()"
    ></app-add-knots>
  </app-context-menu-item>

  <app-context-menu-item
    title="Add Tags"
  >
    <app-add-tags
      [stitchItems]="[message]"
      (close)="close()"
    ></app-add-tags>
  </app-context-menu-item>

  <app-context-menu-item
    title="Reprocess Knots"
    (execute)="reprocessKnots()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Download Original"
    [close]="true"
    (execute)="downloadOriginal()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Print Message"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="message?.archived ? 'Restore From Archive' : 'Archive'"
    [close]="true"
    (execute)="archive()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="message?.deleted ? 'Restore From Trash' : 'Trash'"
    [close]="true"
    (execute)="trash()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="message?.deleted"
    title="Delete Permanently"
    [close]="true"
    (execute)="deletePermanently()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Settings"
    [close]="true"
    (execute)="openSettings()"
  ></app-context-menu-item>
</ng-container>

<ng-container *ngIf="multiple">
  <app-context-menu-item
    [title]="message?.pinned ? 'Unpin Selected' : 'Pin Selected'"
    [close]="true"
    (execute)="pin()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="message?.flagged ? 'Unflag Selected' : 'Flag Selected'"
    [close]="true"
    (execute)="flag()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="(selectedItems | testAllElements : [true, false] : 'bulk')"
    [title]="message?.bulk ? 'Not Bulk' : 'Move to Bulk'"
    [close]="true"
    (execute)="bulk()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="(selectedItems | testAllElements : [true, false] : 'spam')"
    [title]="message?.spam ? 'Not Spam' : 'Move to Spam'"
    [close]="true"
    (execute)="spam()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Forward Selected"
    [close]="true"
    (execute)="forward('forward')"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Forward As Attachments"
    [close]="true"
    (execute)="forward('forwardAsAttachment')"
  ></app-context-menu-item>

  <app-context-menu-divider *ngIf="selectable"></app-context-menu-divider>

  <app-context-menu-item
    *ngIf="selectable"
    title="Select All"
    [close]="true"
    (execute)="selectAll()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Snooze"
  >
    <app-postpone-context-menu
      [items]="selectedItems"
      (onSelect)="postpone($event, 'snooze')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-item
    title="Follow up"
  >
    <app-postpone-context-menu
      [items]="selectedItems"
      appearance="followUp"
      (onSelect)="postpone($event, 'followUp')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Copy To"
  >
    <app-move-to
      appearance="copy"
      [items]="selectedItems"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Move To"
  >
    <app-move-to
      appearance="move"
      [items]="selectedItems"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Stitch"
  >
    <app-stitch-to
      [stitchItems]="selectedItems"
      (close)="close()"
    ></app-stitch-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Stitch All Together"
    [close]="true"
    (execute)="stitchAllTogether()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Add Knots"
  >
    <app-add-knots
      [stitchItems]="selectedItems"
      (close)="close()"
    ></app-add-knots>
  </app-context-menu-item>

  <app-context-menu-item
    title="Add Tags"
  >
    <app-add-tags
      [stitchItems]="selectedItems"
      (close)="close()"
    ></app-add-tags>
  </app-context-menu-item>

  <app-context-menu-item
    title="Reprocess Knots"
    (execute)="reprocessKnots()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    [title]="message?.archived ? 'Restore From Archive Selected' : 'Archive Selected'"
    [close]="true"
    (execute)="archive()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="message?.deleted ? 'Restore Selected From Trash' : 'Trash Selected'"
    [close]="true"
    (execute)="trash()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="message?.deleted"
    title="Delete Selected Permanently"
    [close]="true"
    (execute)="deletePermanently()"
  ></app-context-menu-item>
</ng-container>
