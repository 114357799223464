// Common
import { Injectable, Injector } from '@angular/core';

// RX
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { Message } from '../types/message';
import { MessageFolder } from '../types/message-folder';
import { ListState } from '@modules/messages/types/list-state';
import { VirtualFolder } from '../types/virtual-folder';
import { Application } from '@modules/common/types/application';
import { type MessageActionType } from '../types/message-action-type';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';

// Components
import { MessageComposeComponent } from '@modules/modal/components/message-compose/message-compose.component';

@Injectable()
export class MailAppStateService extends BaseAppStateService<MessageFolder, Message, VirtualFolder> {

  protected application = Application.mail;
  protected defaultVirtualFolder: VirtualFolder = 'all_messages';

  constructor(
    private modalService: ModalService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Actions
   */

  composeMessage(config: {
    injector: Injector,
    message?: Message,
    reply?: Message,
    forward?: Message[],
    action?: MessageActionType
  }) {
    const { message, reply, forward, action } = config || {};

    let title = 'Compose message';

    if (action === 'forward' || action === 'forwardAsAttachment') {
      title = `Fwd: ${ forward?.[0]?.subject }`;
    } else if (action === 'reply' || action === 'replyAll') {
      title = `Re: ${ reply?.subject }`;
    } else if (message?.to?.length) {
      title = `To: ${ message?.to[0]?.name || message?.to[0]?.address }`;

      if (message?.to?.length > 1) {
        title += `+${ message?.to?.length - 1 }`;
      }
    }

    this.modalService.showModal({
      component: MessageComposeComponent,
      content: { message, action, forward, reply },
      title,
      resize: true,
      collapsible: true,
      expandable: false,
      maxModals: 1,
      icon: 'button-nav-compose',
      injector: config.injector
    });
  }

  getTabs(): Observable<ListState> {
    return this.getVirtualFolder()
      .pipe(
        map(sidebar => {
          switch (sidebar) {
            case 'all_messages':
            case 'bulk':
            case 'spam':
            case 'followed':
            case 'snoozed':
            case 'scheduled':
            case 'sent':
            case 'draft':
              return 'messages';
            case 'flagged':
            case 'archived':
            case 'deleted':
              return 'tabs';
            case 'all_folders':
              return 'folders';
            default:
              return 'messages';
          }
        })
      );
  }
}
