// Common
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { warmUpObservable } from '@decorators';
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

// Services
import { BaseService } from '@modules/common/services/base.service';
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';
import { SignalsService } from '@modules/common/services/signals.service';
import { SpacesService } from '@modules/settings/services/spaces.service';

// Types
import { Notification } from '@modules/notification/types/notification';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { NotificationsFilters } from '@modules/notification/types/notifications-filters';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { ButtonsActionTypes } from '@modules/notification/types/notifications-type';
import { SignalEnum } from '@modules/common/types/signal';

// RxJS
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, scan, startWith, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class NotificationsService extends BaseService {

  constructor(
    private http: HttpClient,
    private stitchServiceFactory: StitchServiceFactory,
    private signalsService: SignalsService,
    private spacesService: SpacesService,
    @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>,
  ) {
    super();
  }

  create(): Observable<Notification> {
    throw new Error('not available');
  }

  search(filters?: Partial<NotificationsFilters>, config?: object): Observable<BaseSearchResponse<Notification> & { unreadCount: number }> {
    const requestParams = { params: new NotificationsFilters(filters || {}).format() };

    return this.getRefreshRequired()
      .pipe(
        switchMap(() => this.spaceId),
        switchMap((spaceId) => this.http.get<{ items: Notification[], count: number, unreadCount: number }>
        (environment.baseUrl + '/api/notifications', requestParams)
            .pipe(map(response => ({ spaceId, response })))
        ),
        switchMap(({ spaceId, response }) =>
          this.signalsService.getSignal(SignalEnum.NEW_NOTIFICATION, spaceId)
            .pipe(
              startWith(null),
              map((notificationSignal) => ({ spaceId, response, notificationSignal }))
            )
        ),
        scan((acc, { spaceId, response, notificationSignal }) => {
          const items = (notificationSignal?.item ? [notificationSignal.item] : []).concat(acc?.items ?? response.items);
          notificationSignal?.item && items.pop();

          return {
            items: items.map(item => new Notification(item)),
            unreadCount: notificationSignal?.unreadCount ?? (acc?.unreadCount ?? response.unreadCount),
            count: notificationSignal?.item ? acc.count + 1 : (acc?.count ?? response.count)
          };
        }, null)
      );
  }

  @warmUpObservable
  markAsRead(isRead = true, ids?: string[], { emit }: FeedbackConfig = { emit: true }): Observable<{ success: boolean }> {
    return this.http.put<{ success: boolean }>
    (environment.baseUrl + '/api/notifications/mark-as-read', { ids: ids, isRead: isRead })
      .pipe(tap(() => emit && this.forceRefresh()));
  }

  getUnreadCount(): Observable<number> {
    return this.search({ limit: 0, offset: 0, unread: true })
      .pipe(
        map(({ unreadCount }) => unreadCount)
      );
  }

  handleAction(
    { notification, buttonActionType, data }: { notification: Notification, buttonActionType: ButtonsActionTypes, data?: unknown }
  ): Observable<void> {
    if (!notification.entity?.id || !notification.entity?.entityType) { return; }

    switch (buttonActionType) {
      case 'followed':
        return this.stitchServiceFactory.getServiceByStitchType(notification.entity.entityType)
          .followUp({ ids: [notification.entity.id] }, data as Date);
      case 'snoozed':
        return this.stitchServiceFactory.getServiceByStitchType(notification.entity.entityType)
          .snooze({ ids: [notification.entity.id] }, data as Date);
      case 'space-accept':
      case 'space-reject':
        return this.spacesService.getOne(notification.entity.id)
          .pipe(
            // switchMap(space => this.spacesService.accept(space, buttonActionType === 'space-accept')),
            map(() => {})
          );
    }
  }
}
