// Common
import { Component, Input, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Types
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { FoldersFilters } from '@modules/messages/types/folders-filters';
import { FoldersListState } from '@modules/messages/types/folders-list-state';
import { Message } from '@modules/messages/types/message';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { MessagesCounts } from '@modules/messages/types/messages-counts';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { MailAppStateService } from '@modules/messages/services/state.service';

// RX
import { takeUntil } from 'rxjs/operators';

// Components
import { BaseSidebarContainersItemComponent } from '@modules/common/components/base-sidebar-containers-item/base-sidebar-containers-item.component';

@Component({
  selector: 'app-side-bar-folders',
  templateUrl: './side-bar-folders.component.html',
  styleUrls: ['../../../common/components/base-sidebar-containers-item/base-sidebar-containers-item.component.less', './side-bar-folders.component.less'],
  animations: [heightAnimation],
})
export class SideBarFoldersComponent extends BaseSidebarContainersItemComponent<MessageFolder, Message, FoldersFilters, VirtualFolder, FoldersListState> implements OnInit {
  @Input() messagesCount: MessagesCounts;

  public application = Application.mail;
  public sidebarSplitViewKey = SidebarSplitViewKey.mail;
  public listStateKey = StateKey.sidebarMessagesFoldersListState;

  constructor(stateService: MailAppStateService) {
    super(stateService);
  }

  public ngOnInit() : void {
    super.ngOnInit();

    this.itemsListState
      .pipe(takeUntil(this.alive))
      .subscribe(state => {
        this.filters = new FoldersFilters().applyListState(state);
      });
  }
}
