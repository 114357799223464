// Common
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

// Types
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Message } from '@modules/messages/types/message';
import { type Participant } from '@modules/messages/types/participant';
import { type DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { MessagesService } from '@modules/messages/services/messages.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

// Pipes
import { FormatParticipantPipe } from '@modules/messages/pipes/format-participant.pipe';

@Component({
  selector: 'app-message-quick-form',
  templateUrl: './message-quick-form.component.html',
  styleUrls: ['../base-quick-form/base-quick-form.component.less', './message-quick-form.component.less'],
})
export class MessageQuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() message: Message = new Message();

  // Outputs
  @Output() more: EventEmitter<Message> = new EventEmitter<Message>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Message> = new EventEmitter();

  // Public
  public contactsSuggestions: AutocompleteFactory<Participant>;
  public popoverClose = new Subject<void>();

  public readonly useInputTextGetValue : (text: string) => DropdownSelectItem<Participant>;

  /**
   * Constructor
   */
  constructor (
    private contactsService: ContactsService,
    public changeDetector: ChangeDetectorRef,
    private messagesService: MessagesService,
    formatParticipantPipe: FormatParticipantPipe
  ) {
    super(changeDetector);
    this.reset();
    this.contactsSuggestions = this.contactsService.getEmailAutocompleteSuggestions();

    this.useInputTextGetValue = (text: string) : DropdownSelectItem<Participant, Participant> => {
      const value : Participant = { address: text };
      return {
        value: value,
        source: value,
        title: formatParticipantPipe.transform(value)
      };
    };
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('message' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const message = Message.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(message);
      return;
    }

    this.saveInProgress = true;

    this.messagesService.create(message)
      .pipe(takeUntil(this.alive))
      .subscribe(
        () => {
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (this.message) {
      this.form = this.message.asFormGroup();
    }

    super.reset();
  }

  clickMore() {
    const message = Message.fromFormGroup(this.form);
    this.more.emit(message);
    super.clickMore();
  }
}
