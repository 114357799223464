<span class="placeholder" *ngIf="items.length === 0">No Calendars found</span>

<div appSelectableList>
  <app-sidebar-item
    *nestedFor="
      let calendar of items;
      let index = index;
      let depth = depth;
      let expandable = expandable;
      let itemExpanded = expanded;
      stateKey: treeStateKey;
    "
    [title]="calendar.title"
    [actions]="[{
      icon: 'sapphire-plus',
      template: itemCreateFormTemplate,
      context: { parentId: calendar.id },
      until: quickFormPopoverClose
    }]"
    [depth]="team ? depth + 2 : depth"
    [badge]="{ template: badgeTemplate, context: { flagged: calendar.flagged, pinned: calendar.pinned } }"
    [expandable]="expandable"
    [expanded]="itemExpanded.value"
    [active]="selectedItemsIds | includesBy : calendar.id"
    [iconColor]="calendar.color"
    [withCheckbox]="true"
    [checked]="fullCalendarViewIds | includesBy : calendar.id"
    (onCheck)="checkFullCalendarView(calendar)"
    (onExpand)="itemExpanded.next($event)"

    (appClick)="handleClick(calendar)"
    (appDblClick)="handleDblClick(calendar)"

    [appCalendarContextMenu]="calendar"

    [appSelectable]="calendar.id"
    [appSelectablePosition]="index"
    [appSelectableIdentifier]="calendar.id"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate"
    (appDroppableDrop)="dndDrop($event, calendar)"

    appDraggable
    [appDraggableData]="items | selectedItemsDragData: selectedItemsIds: calendar"
    [appDraggablePlaceholder]="placeholder"
    [appDraggablePlaceholderContext]="{ item: calendar }"
  />
</div>

<ng-template #badgeTemplate let-flagged=flagged let-pinned=pinned>
  <div class="badge-template">
    <mat-icon
      class="badge-template-icon pinned"
      *ngIf="pinned"
      svgIcon="pin-direct"
    />

    <mat-icon
      *ngIf="flagged"
      class="badge-template-icon flagged"
      svgIcon="starred"
    />
  </div>
</ng-template>
