// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// RX
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Types
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Tab } from '@modules/common/types/tab';
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';
import { TasksListState } from '@modules/tasks/types/tasks-list-state';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';

// Services
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { AlertService } from '@modules/alert/services/alert.service';
import { TaskingAppStateService } from '@modules/tasks/services/state.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.less'],
  providers: [
    { provide: CalendarEventsService, useClass: ProjectsService }
  ]
})
export class ProjectFormComponent extends FullFormBaseComponent<Project> implements OnInit {

  public tasksListStateKey: StateKey = StateKey.tasksFFListState;
  public changesKey = 'project';
  public tabs: Tab[] = [
    { title: 'Project', value: 'project' },
    { title: 'Details', value: 'details' },
    { title: 'Board', value: 'board' },
    { title: 'Sections', value: 'sections' },
    { title: 'Attachments', value: 'attachments'},
    { title: 'Stitch', value: 'stitch' },
    { title: 'Lab', value: 'lab' },
    { title: 'Comments', value: 'comments'},
  ];
  tabsStateKey = 'ffProject';
  public currentSidebarFilter: VirtualFolder;
  public tasksListState = new BehaviorSubject<TasksListState>(null);
  public tasksFilters: TasksFilters;
  public parentSuggestions: AutocompleteFactory<Project>;

  protected dragDataType = DragDataTypes.project;

  @Input() project = new Project();

  constructor (
    private projectsService: ProjectsService,
    private tasksService: TasksService,
    private stateService: TaskingAppStateService,
    injector: Injector,
    private alertService: AlertService,
  ) {
    super(injector, projectsService, stateService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.stateService.getVirtualFolder()
      .pipe(takeUntil(this.alive))
      .subscribe(folder => this.currentSidebarFilter = folder);

    this.stitchItemChanged
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.parentSuggestions = this.projectsService.getAutocompleteSuggestions({ exceptIds: [this.stitchItem.id] });
      });

    this.stitchItemChanged
      .pipe(
        switchMap(() => this.tasksListState),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.tasksFilters = new TasksFilters({ containersIds: [this.stitchItem.id], noParent: true });
        this.tasksFilters.applyListState(this.tasksListState.getValue());
      });
  }

  /**
   * Actions
   */

  public handleCreateTaskOnDrop(dragData: DragData): void {
    if (!this.stitchItem || !dragData || !dragData.data) { return; }

    const task = Task.fromDragData(dragData);
    task.projectId = this.stitchItem.id;

    this.tasksService.create(task, { emit: false })
      .pipe(takeUntil(this.alive))
      .subscribe(() => {});
  }

  public handleLinkOnDrop(dragData: DragData): void {
    this.stitchService.linkDragData(this.stitchItem, dragData);
  }

  dndDrop(dragData: DragData) {
    if (!this.stitchItem) { return; }

    if (
      dragData.type === DragDataTypes.knot ||
      dragData.type === DragDataTypes.tag
    ) {
      this.stitchService.linkDragData(this.stitchItem, dragData);
    } else {
      this.alertService.show({
        title: 'Stitch or Task?',
        body: 'What do we do with the dropped item?',
        rightButtons: [
          {
            title: 'CANCEL',
            close: true
          },
          {
            title: 'Create new Task',
            click: () => { this.handleCreateTaskOnDrop(dragData); },
            close: true
          },
          {
            title: 'Stitch an Item',
            click: () => { this.handleLinkOnDrop(dragData); },
            close: true
          }
        ]
      });
    }
  }

  handleTasksListStateChange(state: TasksListState) {
    this.tasksListState.next(state);
  }

  handleComplete() {
    this.stitchItem.completed = !this.stitchItem.completed;
    this.projectsService.update(this.stitchItem);
  }

  /**
   * Helpers
   */

  get stitchItem(): Project {
    return this.project;
  }

  protected shouldRefreshList(prev, current) {
    return Project.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Project {
    return Project.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.project.asFormGroup(this.projectsService);
  }
}
