// Common
import { Component, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Services
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesService } from '@modules/notes/services/notes.service';

// Types
import { Application } from '@modules/common/types/application';
import { Stitch } from '@modules/common/types/stitch';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';

// RxJs
import { take } from 'rxjs/operators'

// Components
import { BaseSidebarContainersTreeComponent } from '@modules/common/components/base-sidebar-containers-tree/base-sidebar-containers-tree.component';

@Component({
  selector: 'app-side-bar-notebooks-tree',
  templateUrl: './side-bar-notebooks-tree.component.html',
  styleUrls: ['./side-bar-notebooks-tree.component.less'],
  animations: [heightAnimation],
  providers: [SelectableService]
})
export class SideBarNotebooksTreeComponent extends BaseSidebarContainersTreeComponent<Notebook, Note, NotebooksFilters, VirtualFolder> implements OnInit {

  selfDragDataTypes = [DragDataTypes.note, DragDataTypes.notebook];
  application = Application.notes;
  treeStateKey = StateKey.notesSidebarNotebooksTree;

  public dndPredicate = (stitchItem: Stitch) => (dragData: DragData): boolean =>
    stitchItem &&
    !(
      dragData.type === DragDataTypes.notebook &&
      dragData.data.length === 1 &&
      dragData.data?.[0]?.id === stitchItem?.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor(
    private notebooksService: NotebooksService,
    private notesService: NotesService,
    notesAppStateService: NotesAppStateService,
  ) {
    super(notebooksService, notesAppStateService);
  }

  /**
   * Actions
   */

  public handleMove(dragData: DragData, item: Notebook) {
    const message = 'Successfully moved to ' + item.title;

    if (dragData.type === DragDataTypes.note) {
      this.notesService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { notebookId: item.id },
        { message }
      );
    } else if (dragData.type === DragDataTypes.notebook) {
      this.notebooksService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { parentId: item.id },
        { message }
      );
    }
  }

  public handleDuplicate(dragData: DragData, notebook: Notebook) {
    if (dragData.type === DragDataTypes.note) {
      this.notesService.duplicate(this.getIds(dragData.data), notebook.id)
        .pipe(take(1))
        .subscribe();
    } else if (dragData.type === DragDataTypes.notebook) {
      this.notebooksService.duplicate(this.getIds(dragData.data))
        .pipe(take(1))
        .subscribe();
    }
  }
}
