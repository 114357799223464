// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { PostponeType } from '@modules/common/types/postpone-type';
import { Application } from '@modules/common/types/application';

// RX
import { combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Containers
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-group-context-menu',
  templateUrl: './group-context-menu.component.html',
  styleUrls: ['../stitch-context-menu/stitch-context-menu.component.less', './group-context-menu.component.less']
})
export class GroupContextMenuComponent extends StitchContextMenuComponent<Group> implements OnInit, OnChanges {

  @Input() group: Group;

  @Output() openContact = new EventEmitter<Contact>();

  applicationName = Application.contacts;
  gaPrefix = 'group-context-menu';
  public form: UntypedFormGroup;

  constructor (
    private groupsService: GroupsService,
    private toasterService: ToasterService,
    injector: Injector,
  ) {
    super(injector, groupsService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.group;
    super.ngOnInit();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('group' in changes) {
      this.item = this.group;
      this.resetForm();
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.form.invalid) { return; }

    this.groupsService.update(Group.fromFormGroup(this.form))
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => this.close());
  }

  resetForm() {
    this.form = (this.group || new Group()).asFormGroup();
  }

  handleContactFormMore(contact: Contact) {
    this.openContact.emit(contact);
  }

  duplicate() {
    if (!this.group) { return; }

    this.groupsService.duplicate(this.getIds())
      .pipe(take(1))
      .subscribe();
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.groupsService[postponeType]({ ids: this.getIds() }, date);
  }

  deletePermanently() {
    super.deletePermanently(`When deleting, all contacts in ${ this.multiple ? 'these' : 'this' } group${ this.multiple && 's' } will be removed also`)
  }
}
